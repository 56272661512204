(function($) {
    /*
    $('#load_more_news').on('click', function() {
        let page = new URLSearchParams(window.location.search).get('page') || 0;
        page = Number(page) + 1;
        const url = $(this).data('api') + '&page=' + page;
        console.log(url)
        $.getJSON(url, ({ data }) => {
            updatePageParameterInURL(page);
            if (data.length) {
                data.map(item => console.log(item));
            }
        })

    });

    function updatePageParameterInURL (page) {
        const url = new URLSearchParams(window.location.search)
        url.set('page', page)
        let output = window.location.protocol + '//' + window.location.host;
        output += window.location.pathname;
        output += '?' + url.toString();

        window.history.pushState({ page: page}, '', output);
    }
    */

    $('.lazy').each((index, image) => {
        // image.setAttribute('src', image.getAttribute('data-src'));
        const observer = new window.IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.setAttribute('src', entry.target.getAttribute('data-src'));
                }
            });
        }, { threshold: 0.1 });
        observer.observe(image);
    })
})(window.$);
