import copy from 'copy-to-clipboard';

(function ($) {
    const $hamburger = $('.Header__hamburger > .hamburger');
    const $back = $('.Header__back');
    const $share = $('.Header__share');
    const activeClass = 'is-active';

    $hamburger.on('click', () => {
        if ($hamburger.hasClass(activeClass)) {
            $hamburger.removeClass(activeClass);
            $('.Sections').addClass('Sections--hidden');
            $('body').css('overflow', 'auto');
        } else {
            $hamburger.addClass(activeClass);
            $('.Sections').removeClass('Sections--hidden');
            $('body').css('overflow', 'hidden');
        }
    });

    $back.on('click', () => {
        window.history.back();
    })

    // Share API
    $share.on('click', function(){
        const pageURL = window.btoa(document.location.href);
        console.log(pageURL);
        const url = 'https://r.tekbreak.com/?op=create&format=base64&url='  + pageURL;

        $.get(url, function(data) {
            if (!data.success) {
                return
            }
            const urlToCopy = data.result;
            const fallbackCopy = urlToCopy => {
                console.log('Share api not supported')
                copy(urlToCopy)
            }
            if (navigator.share) {
                navigator.share({
                    title: 'DiarioSur',
                    url: urlToCopy
                }).then(() => {
                    console.log('Thanks for sharing!')
                }).catch(() => {
                    fallbackCopy(urlToCopy)
                })
            } else {
                fallbackCopy(urlToCopy)
            }
        })
    })
    
    
    // Show home button. Not same referer
    const showHomeButton = document.referrer.indexOf(window.location.protocol + "//" + window.location.host) !== 0;
    if (showHomeButton) {
        const backButton = document.querySelector('.Header__back')
        if (backButton) {
            backButton.classList.add('Header__back--home');
            backButton.innerHTML = '<a href="/"><img src="/images/home.png" /></a>'
        }
    }
})(window.$);